import { FunctionComponent } from "preact";
import { useEffect, useState } from "preact/hooks";
import classNames from "classnames";
import { useBus } from "react-bus";
import Markdown from "markdown-to-jsx";
import { IProductGroup } from "~/types/product";
import CheckSVG from "~/assets/icons/check.svg?react";
import { useBotStore } from "~/stores";
// import AskMeAboutSVG from "~/assets/icons/ask_me_about.svg?react";
import "./styles.scss";

interface IProductGroupProps {
  productGroups: IProductGroup[];
}

const ProductGroup: FunctionComponent<IProductGroupProps> = ({ productGroups }) => {
  const bus = useBus();
  const [checkedSet, setCheckedSet] = useState(new Set());
  const [selected, setSelected] = useState<string[]>(
    productGroups?.map((group) => group.products[0].id)
  );
  const { removeCompareData, pushCompareData, compareData } = useBotStore();

  useEffect(() => {
    const intersectionSet = checkedSet.intersection(new Set(compareData?.map((d) => d.product_id)));
    setCheckedSet(intersectionSet);
  }, [compareData.length]);

  useEffect(() => {
    setSelected(productGroups?.map((group) => group.products[0].id));
  }, [productGroups?.length, productGroups?.map((p) => p.category).join(",")]);

  if (!productGroups?.length) {
    return null;
  }

  return (
    <div className="product-group-message">
      {productGroups.map((productGroup, groupIndex) => {
        const selectedProduct = productGroup.products.find((p) => p.id === selected[groupIndex]);
        const isChecked = checkedSet.has(selectedProduct?.id);
        const formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: selectedProduct?.productVariants[0].priceInfo?.currencyCode ?? "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });

        return (
          <div className="product-group-item" key={productGroup.summary}>
            <div className="product-group-item-summary">
              <Markdown
                className="markdown-body is-bot"
                options={{
                  wrapper: "div",
                  forceWrapper: true,
                }}
              >
                {productGroup.summary}
              </Markdown>
            </div>
            <div className="product-group-item-selected">
              <div
                className={classNames("checkbox", { checked: isChecked })}
                onClick={() => {
                  if (isChecked) {
                    removeCompareData(selectedProduct?.id as string);
                    checkedSet.delete(selectedProduct?.id);
                  } else {
                    pushCompareData({
                      product_id: selectedProduct?.id,
                      product_images: selectedProduct?.productVariants[0].productVariantImages,
                    });
                    checkedSet.add(selectedProduct?.id);
                  }
                  bus.emit("productSelect");
                  setCheckedSet(new Set(checkedSet));
                }}
              >
                <span className="inner">{isChecked && <CheckSVG />}</span>
                <img
                  className="product-cover"
                  src={selectedProduct?.productVariants[0].productVariantImages[0].url}
                />
              </div>
              <div className="product-info-box">
                <div>
                  <div className="title">{selectedProduct?.name}</div>
                  <div className="price-box">
                    <span className="latest-price">
                      {formatter.format(selectedProduct?.productVariants[0].priceInfo?.price ?? 0)}
                    </span>
                    <span className="original-price">
                      {formatter.format(
                        selectedProduct?.productVariants[0].priceInfo?.compareAtPrice ?? 0
                      )}
                    </span>
                  </div>
                </div>
                {/* <div>
      <span className="ask-me-about">
        <AskMeAboutSVG />
        Ask Me About
      </span>
    </div> */}
              </div>
            </div>
            <div className="product-group-item-list">
              <div>
                {productGroup.products.map((product) => {
                  return (
                    <img
                      key={product.id}
                      src={product.productVariants[0].productVariantImages[0].url}
                      className={classNames("product-thubnail", {
                        active: selected[groupIndex] === product.id,
                        selected: checkedSet.has(product.id),
                      })}
                      onClick={() => {
                        selected[groupIndex] = product.id;
                        setSelected([...selected]);
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProductGroup;
