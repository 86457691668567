import Icon from "@ant-design/icons";
import classnames from "classnames";
import { FunctionComponent, useRef } from "preact/compat";
import { useSearch } from "wouter-preact";
import sendSVG from "~/assets/icons/send.svg?react";
import stopSVG from "~/assets/icons/stop.svg?react";
import { MessageState } from "~/types";
import useModeClassName from "~/hooks/useModeClassName";
import { useMessageStore } from "~/stores";
import ContentEditable, { ContentEditableEvent } from "react-contenteditable";
import sanitizeHtml from "sanitize-html";
import Turndown from "turndown";

import "./styles.scss";
import { useCategoryInEditor } from "./useCategory";

const turndown = new Turndown();
turndown.addRule("TpCategory", {
  // @ts-expect-error custom tag
  filter: "tp-category",
  // @ts-expect-error custom tag render
  replacement: function (content: string, node: HTMLElement) {
    const id = node.id;
    const name = node.getAttribute("name");
    return id && name ? name : "";
  },
});

interface IProps {
  sendTextMessage: (text: string) => void;
  stopReceive: () => void;
  placeholder: string;
  brandColor: string;
  scrollToBottom: () => void;
}

const BotEditor: FunctionComponent<IProps> = ({
  sendTextMessage,
  placeholder = "Type a message...",
  brandColor,
  stopReceive,
}) => {
  const text = useRef("");
  const { messageState } = useMessageStore();
  const editorDomRef = useRef<ContentEditable>(null);
  const searchParams = useSearch();
  const inputNotAllowed = new URLSearchParams(searchParams).get("inputNotAllowed");
  const modeClassName = useModeClassName();

  const state = useRef(messageState);
  state.current = messageState;

  const { clearCategory } = useCategoryInEditor(editorDomRef);

  const sanitize = () => {
    text.current = sanitizeHtml(text.current, {
      allowedTags: ["b", "i", "em", "strong", "a", "p", "h1", "h2", "h3", "tp-category"],
    });
  };

  const handleSendMessage = () => {
    const inputText = editorDomRef.current?.getEl()?.innerHTML || text.current;
    console.log("inputText: ", inputText);
    const textToSend = turndown.turndown(inputText).trim();
    console.log("textToSend: ", textToSend);
    if (!textToSend || state.current !== MessageState.AVAILABLE) {
      return;
    }
    sendTextMessage(textToSend);
    resetTextarea();
  };

  // 当用户按下回车键时，触发此函数
  const handleInputKeyDown = (event: KeyboardEvent) => {
    // 处理 shift + enter 的换行
    if (event.key === "Enter" && (event.shiftKey || event.altKey)) {
      const selection = window.getSelection();
      if (selection) {
        const range = selection.getRangeAt(0);
        const textNode = document.createTextNode("\ufeff");
        range.deleteContents();
        range.insertNode(document.createElement("br"));
        range.collapse(false);
        range.insertNode(textNode);
        range.setEnd(textNode, 0);
        selection.removeAllRanges();
        selection.addRange(range);
      }

      // 开始换行
      event.preventDefault();
      return;
    }

    if (event.key === "Enter") {
      event.preventDefault(); // 禁止 enter 之后的默认换行
      handleSendMessage();
    }
  };

  const handleAction = () => {
    if (messageState === MessageState.SENDWAITING || messageState === MessageState.SENDING) {
      // 如果当前状态是等待中，那么调用 sendTextMessage 函数，并传入空字符串
      stopReceive();
    } else {
      // 否则，调用 handleSendMessage 函数
      handleSendMessage();
    }
    resetTextarea();
  };

  const resetTextarea = () => {
    text.current = "";
    clearCategory();
  };

  const handleInputChange = (event: ContentEditableEvent) => {
    text.current = event.target?.value;
  };

  const handlePaste = (event: ClipboardEvent) => {
    event.preventDefault();
    const pastePlainText = event.clipboardData?.getData("text/plain");
    const selection = window.getSelection();
    if (pastePlainText && selection) {
      const range = selection.getRangeAt(0);
      const textNode = document.createTextNode(pastePlainText);
      range.deleteContents();
      range.collapse(false);
      range.insertNode(textNode);
      range.setStartAfter(textNode);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  return (
    <div className="bot-editor">
      <div className={classnames("bot-editor-box", { ...modeClassName })}>
        <ContentEditable
          // @ts-expect-error ref
          ref={editorDomRef}
          className="editor-textarea"
          style={{ cursor: inputNotAllowed ? "not-allowed" : "auto" }}
          html={text.current}
          disabled={inputNotAllowed === "true"}
          placeholder={placeholder}
          // @ts-expect-error input
          onKeyDown={handleInputKeyDown}
          onChange={handleInputChange}
          onBlur={sanitize}
          // @ts-expect-error paste
          onPasteCapture={handlePaste}
        />
        <div className="action" onClick={handleAction}>
          {messageState === MessageState.SENDWAITING || messageState === MessageState.SENDING ? (
            <Icon component={stopSVG} style={{ color: brandColor }} />
          ) : (
            <Icon component={sendSVG} style={{ color: brandColor }} />
          )}
        </div>
      </div>
    </div>
  );
};

export default BotEditor;
